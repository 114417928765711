body {
  margin: 0 0 100px;
  /* Match height on footer */
  padding-bottom: 200px;

	color: $font-color;
	background-color: $backgroundcolor;
	font-family: $font-style;
	font-size: 1.1em;
	line-height: 1.6;
	-webkit-font-smoothing: antialiased;
}

h1,h2,h3 {
	font-weight: 600;
	color: $heading-font-color;
	font-family: $heading-font;
	line-height: 1.5;
}
h1 {
	/* color: $main; */
	font-size: 2em;
	a,
	a:visited {
		text-decoration: none;
		color: $main;
	}
}
h2 {
	font-size: 1.5em;
}
h3 {
	font-size: 1em;
	/* color: $lightfontcolor; */
}

input,select {
	width: 270px;
}

a,a:visited {
	color: $link-color;
	text-decoration: underline;
	font-weight: bold;
}
a:hover {
	color: $link-hover-color;
	text-decoration: underline;
}

header {
	background: $header;
	padding: 0px 15px;
  margin: 50px 0 0;
  height: 19vh;
  display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	background-image: url('/assets/banner/banner-6.png');
	width:100%;
}

@media (max-width: 40em) {
  header {
  background: $header;
    background-image: none !important;
  }
}

main {
	padding: 0 15px;
	max-width: $content-width;
	margin: 0 auto;
}

/*
time {
	color: #898989;
}
*/

.container {
  max-width: $content-width;
	margin: 0 auto;
}

.container {
  background-color: #333;
}
.error {
	font-size: 0.8em;
	color: red;
}

.altrow {
	background: $alttablerowcolor;
}

html {
  position: relative;
  min-height: 100%;
}

footer {
	text-align: left;
	font-size: 0.8em;
	background: $header;
	color: $lightfontcolor;

	position: absolute;
  left: 0;
  bottom: 0;
  /* Match padding-bottom on html */
  height: 200px;
  width: 100%;
  overflow: hidden;
}

/* footer layout */
.column {
  float: left;
	padding: 0 15px;
}

.left, .right {
  width: 25%;
  vertical-align: top;
}

.middle {
  width: 50%;
  vertical-align: top;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.div-2-col {
  -webkit-columns: 100px 2;
  -moz-columns: 100px 2;
  columns: 100px 2;
  border: 2px solid #FAFAFA;
}

.ul_one {
  list-style: none;
}

.multicol-container {
  display: table; /* Make the container element behave like a table */
  width: 90%;    /* Set full-width to expand the whole page */
  padding: 6px;
}

.multicol {
  display: table-cell; /* Make elements inside the container behave like table cells */
}

.multibut-container {
  display: table; /* Make the container element behave like a table */
  width: 100%;    /* Set full-width to expand the whole page */
  background: $header; 
  color: white; 
  line-height: 3.5;
}

img {
  max-width: 100%;
  height: auto;
}

/* for wrapping instagram feed*/
.insta_wrap {
  width:100%;
  margin:3 auto;
}
.insta_left_col {
  float:center;
  width:20%;
}
.insta_right_col {
  font-size: 13px;
  font-family: $font-style;
  float:right;
  width:80%;
  -webkit-font-smoothing: antialiased;
}

label {
  /* To make sure that all labels have the same size and are properly aligned */
  display: inline-block;
  width: 180px;
  text-align: left;
}

input, textarea {
  /* To make sure that all text fields have the same font settings
     By default, textareas have a monospace font */
  font-family : inherit;
  font-size   : 100%;

  /* To give the same size to all text fields */
  width: 270px;
  box-sizing: border-box;

  /* To harmonize the look & feel of text field border */
  border: 1px solid #999;
}

input:focus, textarea:focus {
  /* To give a little highlight on active elements */
  border-color: #000;
}

textarea {
  /* To properly align multiline text fields with their labels */
  vertical-align: top;

  /* To give enough room to type some text */
  height: 5em;
}

.button {
  align: center;
  font-family : inherit;
  font-size   : 100%;
}

button {
  /* This extra margin represent roughly the same space as the space
     between the labels and their text fields */
  margin-left: .5em;
}

fieldset {
  margin: 0 auto;
  width: 100%;
  /* To see the outline of the form */
  padding: 1em;
  border: 1px solid #CCC;
  border-radius: 1em;
  margin-top: 1em;
}

.checkbox input {
  display: inline-block;
  width: 40px;
  text-align: left;
}

.checkbox label {
  display: inline-block;
  width: 300px;
  text-align: left;
}

/* This is the new funky new version menubar 
https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_topnav
*/

.topnav {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background: $backgroundcolor;
  font-size: 0.9em;
  width: 100%;
}

.topnav a {
  text-decoration: none;
  display: block;
  padding: 14px 16px;
  float: left;
  background: $backgroundcolor;
}

.topnav a:hover {
  background: $header;
  text-decoration: none;
  color: $main;
}

.active {
  /* background-color: #4CAF50; */
  background: $header;
  text-decoration: none;
  color: $main;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 40em) {
  .topnav a {display: none;}
  .topnav a.icon {
  float: right;
  display: block;
  }
}

@media screen and (max-width: 40em) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

/* https://www.bestcssbuttongenerator.com/ */
.myButton {
  -moz-box-shadow: 0px 0px 0px 2px #9fb4f2;
  -webkit-box-shadow: 0px 0px 0px 2px #9fb4f2;
  box-shadow: 0px 0px 0px 2px #9fb4f2;
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #7892c2), color-stop(1, #476e9e));
  background:-moz-linear-gradient(top, #7892c2 5%, #476e9e 100%);
  background:-webkit-linear-gradient(top, #7892c2 5%, #476e9e 100%);
  background:-o-linear-gradient(top, #7892c2 5%, #476e9e 100%);
  background:-ms-linear-gradient(top, #7892c2 5%, #476e9e 100%);
  background:linear-gradient(to bottom, #7892c2 5%, #476e9e 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#7892c2', endColorstr='#476e9e',GradientType=0);
  background-color:#7892c2;
  -moz-border-radius:10px;
  -webkit-border-radius:10px;
  border-radius:10px;
  border:1px solid #4e6096;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-family:Arial;
  font-size:19px;
  padding:12px 37px;
  text-decoration:none;
  text-shadow:0px 1px 0px #283966;
}
.myButton:hover {
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #476e9e), color-stop(1, #7892c2));
  background:-moz-linear-gradient(top, #476e9e 5%, #7892c2 100%);
  background:-webkit-linear-gradient(top, #476e9e 5%, #7892c2 100%);
  background:-o-linear-gradient(top, #476e9e 5%, #7892c2 100%);
  background:-ms-linear-gradient(top, #476e9e 5%, #7892c2 100%);
  background:linear-gradient(to bottom, #476e9e 5%, #7892c2 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#476e9e', endColorstr='#7892c2',GradientType=0);
  background-color:#476e9e;
}
.myButton:active {
  position:relative;
  top:1px;
}

